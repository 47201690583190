import styled from 'styled-components'
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import "@babel/polyfill";

import Layout from "../layouts"
import Head from "../components/head"
import Pagination from "../components/pagination"
import SectionHero from "../components/section-hero";

import '../css/events.css';

import YoastSeoParts from '../fragments/seo';

class EventTemplate extends Component {

  render() {
    const page = this.props.data.wpPage;
    const { seo } = page
    let events = this.props.data.allWpEvent.nodes;

    const { currentPage, numPages } = this.props.pageContext

    if (page.template.events_webinars.eventsOrWebinars === "webinars"){
      events = this.props.data.allWpPastWebinar.nodes;
    }
    console.log(events);

    let today = new Date();
    today = today.toISOString().substring(0, 10)


    return (
      <Layout>
          <Head title={seo.title} description={seo.metaDesc} meta={seo} />
          <SectionHero section="resource" title={page.title}/>

          <div className="outer-container eventContainer">
          

              { events &&
                events.map(event => {
                  var backgroundStyles = {}

                  if(event.featuredImageId)
                    backgroundStyles.backgroundImage = "url('"+event.featuredImage.node.localFile.publicURL+"')";

                  // if(event.cptEvent.startDateTime.substring(0, 10) < today)
                  //   return;

                  return(
                    <div key={event.id} className="eventCol">
                      <div className="eventBox">
                        <Link to={event.link}>
                        
                          <div className="eventBoxImage">
                          <img className="resource eventImage" src={event.featuredImage.node.localFile.publicURL}></img>
                          </div>
                           <div className="eventBoxBody">
                            <h2>{event.title}</h2>
                           <div dangerouslySetInnerHTML={{ __html: event.excerpt }}></div>
                            <p>Learn More</p>
                          </div>
                          </Link>
                        </div>
                      </div>
                  )
                })
              }

              <Pagination uri={page.uri} currentPage={currentPage} numPages={numPages} />

            </div>
          
      </Layout>
    )
  }
}

export default EventTemplate

EventTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export const query = graphql`
  query($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      link
      uri
      id
      databaseId
      seo {
        ...seoParts
      }
      template {
      ... on WpTemplate_Events {
        templateName
        events_webinars {
          eventsOrWebinars
          } 
        }
      }
    }

    allWpEvent (
      filter: {status: {eq: "publish"}}
      sort: {fields: cptEvent___startDateTime, order: DESC},
      limit: 30,
    ) {
      nodes {
        id
        title
        link
        excerpt
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          node {
            altText
            localFile {
              publicURL
            }
          }
        }
        featuredImageId
        cptEvent {
          startDateTime
        }
      }
    }
    allWpPastWebinar (
      filter: {status: {eq: "publish"}}
      sort: {fields: cptPastWebinar___startDateTime, order: DESC}
      limit: 30,
    ) {
      nodes {
        id
        title
        link
        excerpt
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          node {
            altText
            localFile {
              publicURL
            }
          }
        }
        cptPastWebinar {
          startDateTime
        }
      }
    }  
  }
`